import React, { Fragment } from "react"
import { Link } from "gatsby"
import ImageMeta from "../../components/ImageMeta"
import classNames from "classnames"

function createHtml(html) {
  return { __html: html }
}
const showdown = require("showdown")
const converter = new showdown.Converter()

// currently handles 1 and 5 doctors
// new docGridClass className will need to be made and css added for other amounts
const MeetTheDoctors = ({ doctors, markdown, colorBack, pageName = "" }) => {
  const mainClasses = classNames({
    "color-back": colorBack
  })
  const docGridClasses = classNames({
    "doctor-col-with-1": doctors.length === 1,
    "doctor-col-with-5": doctors.length === 5
  })

  return (
    <div className={`${pageName} ${mainClasses} meet-doctors`}>
      <div className="columns">
        <div className="column is-2"></div>
        <div className="column">
          <div
            className="column has-text-centered-tablet"
            dangerouslySetInnerHTML={createHtml(converter.makeHtml(markdown))}
          ></div>
        </div>
        <div className="column is-2"></div>
      </div>

      <div className="columns">
        <div className="column is-2"></div>
        <div className="column doctors-row">
          {doctors &&
            doctors.map((dr, i) => (
              <div key={i} className={docGridClasses}>
                <Link to={dr.doctor.url}>
                  <ImageMeta
                    cloudName="nuvolum"
                    publicId={dr.doctor.imageId}
                    width="auto"
                    responsive
                    className="meet-our-doctor"
                  ></ImageMeta>
                </Link>
                <div className="doctor-learn">
                  <p>{dr.doctor.caption}</p>
                  <Link to={dr.doctor.url}>Learn More</Link>
                </div>
              </div>
            ))}
        </div>
        <div className="column is-2"></div>
      </div>
    </div>
  )
}

export default MeetTheDoctors
