import PropTypes from "prop-types"
import React from "react"

import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import TheaterVideo from "@components/Theater/TheaterVideo"

export default function ButtonGroupMap(props) {
  if (!props.buttons) {
    console.warn(
      `ButtonGroupMap received no buttons. Props are as follows: ${JSON.stringify(
        props
      )}`
    )
    return null
  }

  let allButtonsComplete = true

  for (let i = 0; i < props.buttons.length; i++) {
    let button = props.buttons[i].button
    if (button.buttonText === "" || button.href === "") {
      allButtonsComplete = false
      break
    }
  }

  if (!allButtonsComplete) {
    console.warn(
      "Button group has an incomplete button, so the button group was not rendered."
    )
    return null
  }

  return (
    <ButtonGroup {...props}>
      {props.buttons &&
        props.buttons.map(buttonObj => {
          let button = buttonObj.button
          if (button.appearance === "video") {
            return (
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${button.youtube}`}
                controls={true}
                playing={true}
                onlyButton={true}
                language={"en"}
                buttonClass="contained"
              />
            )
          } else {
            return (
              <Button
                contained={button.appearance === "contained"}
                minimal={button.appearance === "minimal"}
                external={button.destination === "external"}
                tel={button.destination === "tel"}
                key={button.href}
                buttonText={button.buttonText}
                href={button.href}
              />
            )
          }
        })}
    </ButtonGroup>
  )
}

ButtonGroupMap.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      button: PropTypes.shape({
        buttonText: PropTypes.string,
        href: PropTypes.string,
        youtube: PropTypes.string,
        appearance: PropTypes.string.isRequired,
        destination: PropTypes.string
      })
    })
  )
}
